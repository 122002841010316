.about-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 20px;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.about-title {
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 10px;
}

.about-inner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-us-img {
  width: 100%;
  height: 300px;

  border-radius: 10px;
}

.about-text {
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}

.text-style {
  margin-bottom: 20px;
}

@media (min-width: 550px) and (max-width: 778px) {
  .about-us-img {
    height: 500px;

    border-radius: 10px;
  }
}

@media (min-width: 778px) {
  .about-wrapper {
    justify-content: space-between;
   
    padding: 30px 20px;
    margin: 25px 6.5%;
  }

  .about-inner-wrapper {
    display: flex;
    flex-direction: row;
    gap: 2%;
  }

  .about-us-img {
    width: 40%;
    height: 600px;

    border-radius: 10px;
  }

  .about-title {
    width: 20%;
    font-size: 25px;
  }

  .about-text {
    width: 55%;
    font-size: 25px;
    line-height: 25px;
  }
}
